import React, { useContext, useState, useEffect } from 'react'
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Popover from "react-bootstrap/Popover";
import { OverlayTrigger } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import CountdownTimer from '../Dashboard/CountdownTimer';

export default function Header() {
    const { account, connectMetamask, disconnectNow, dbuser, dbuserload, formatAddress, settings } = useContext(ProjectContext);
    const [showMenu, setShowMenu] = useState(false);
    const [show1, setShow1] = useState(true);
    const handleClose1 = () => setShow1(false);
    var navigate = useNavigate();
    const { link_ref_address } = useParams();
    const logout = () => {
        disconnectNow();
        navigate('/')
    }
    useEffect(() => {
        if (dbuserload && dbuser !== null) {
            navigate("/dashboard", dbuserload && dbuser !== null, dbuserload, dbuser);
        } else if (dbuserload && account && dbuser === null) {
            console.log("dasdas", link_ref_address);

            if (link_ref_address) {
                navigate(`/login/${link_ref_address}`);
            } else {
                navigate("/login");
            }
        }
    }, [dbuser, dbuserload, account])
    const popoverTop = (
        <Popover
            id="popover-positioned-top"
            className="popover"
            title="Popover top"
        >
            <strong>
                <a href="https://help.yblwin.ai/" target="_blank" rel="noreferrer" style={{ fontSize: "12px" }}>
                    Need <br /> Help ?
                </a>
            </strong>
        </Popover>
    );
    const [showButton, setShowButton] = useState(false);

    const [seconds, setSeconds] = useState(10);

    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);

            // Clear the interval when the component is unmounted or when the timer reaches 0
            return () => clearInterval(interval);
        }
    }, [seconds]);
    useEffect(() => {
        if (settings) {
            if (settings.notifi_status == true) {
                setShow1(true);
            }
            else {
                setShow1(false);
            }
        }
    }, [settings]);

    setTimeout(() => {
        setShowButton(true);
    }, 10000);
    return (
        <>
            <div>
                <div className={showMenu ? "th-menu-wrapper th-body-visible" : "th-menu-wrapper"}>
                    <div className="th-menu-area text-center"><button className="th-menu-toggle" onClick={() => setShowMenu(false)}><i className="fal fa-times" /></button>
                        <div className="mobile-logo"><Link to="/"><span data-mask-src="/assets/img/logo.png" className="logo-mas" /><img src="/assets/img/logo.png" alt="Bame" /></Link></div>
                        <div className="th-mobile-menu">
                            <ul>
                                <li><a href='#About' onClick={() => setShowMenu(false)}>About Us</a></li>
                                <li><a href='#Games' onClick={() => setShowMenu(false)}>Games</a></li>
                                <li><a href='#Features' onClick={() => setShowMenu(false)}>Features</a></li>
                                <li><a href='#Transparency' onClick={() => setShowMenu(false)}>Transparency</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <header className="th-header header-layout2">
                    <div className="sticky-wrapper">
                        <div className="container">
                            <div className="row gx-0 justify-content-between">
                                <div className="col-lg-auto col">
                                    <div className="header-logo-wrap">
                                        <div className="header-logo"><Link to="/"><span data-mask-src="/assets/img/logo.png" className="logo-mask" /> <img src="/assets/img/logo.png" alt="Bame" /></Link></div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="menu-area">
                                        <div className="row gx-0 align-items-center justify-content-lg-between justify-content-end">
                                            <div className="col-auto">
                                                <nav className="main-menu d-none d-lg-inline-block">
                                                    <ul>
                                                        <li><a href='#About'>About Us</a></li>
                                                        <li><a href='#Games'>Games</a></li>
                                                        <li><a href='#Features'>Features</a></li>
                                                        <li><a href='#Transparency'>Transparency</a></li>
                                                    </ul>
                                                </nav>
                                                <div className="header-button d-flex d-lg-none"><button type="button" className="th-menu-toggle" onClick={() => setShowMenu(true)}><span className="btn-border" /><i className="far fa-bars" /></button></div>
                                            </div>
                                            <div className="col-auto  d-xl-none d-block">
                                                <div className="header-button">

                                                    <div className="d-xl-none d-block">
                                                        {account ?
                                                            <>
                                                                <div className="header-button d-flex d-lg-none">
                                                                    <button type="button" className="th-menu-toggle" onClick={() => logout()}><span className="btn-border" /><i className="far fa-sign-out" />
                                                                    </button>
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="header-button d-flex d-lg-none">
                                                                <button type="button" className="th-menu-toggle" onClick={() => connectMetamask()}><span className="btn-border" /><i className="far fa-wallet" />
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto d-none d-xl-block d-md-block">
                                                <div className="header-button">
                                                    {/* <button type="button" className="simple-icon searchBoxToggler d-none d-xxl-block"><i className="far fa-search" /></button> */}
                                                    <div className="d-xl-block d-md-block d-none">
                                                        {account ?
                                                            <>
                                                                <div className='d-flex'>
                                                                    <Link to='/login' className="th-btn">{formatAddress(account)} <i className='fa fa-copy me-1' /></Link>
                                                                    <button type="button" className="th-btn" onClick={() => logout()}>Logout<i className="fa-solid fa-sign-out" /></button>
                                                                </div>
                                                            </>
                                                            :
                                                            <button className="th-btn" onClick={() => connectMetamask()}><i className="fa-brands fa-twitch me-1" />Wallet Connect</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <OverlayTrigger trigger="click" placement="top" overlay={popoverTop}>
                    {/* <img width={90} height={80} src="assets/assets/img/chat-boat.png" alt="" className='sticky' style={{ cursor: 'pointer', float: "right", position: "absolute", bottom: "0", zIndex: "999", paddingRight: "10px", right: "0" }} /> */}
                    <img
                        width={70}
                        height={70}
                        src="assets/img/chat_bot.png"
                        alt=""
                        className="sticky"
                    />
                </OverlayTrigger>
            </div>
            <Modal show={show1} centered>
                <div className='d-flex justify-content-center rounded'>
                    {/* <div class="modal-content p-0"> */}
                    {/* <div className="modal-header d-flex justify-content-between">
                            <h5 className="modal-title" id="exampleModalLabel">News</h5>

                        </div> */}
                    <Modal.Body>
                        {/* <div className="modal-body p-0"> */}

                        <div dangerouslySetInnerHTML={{ __html: settings.notification }} />
                        {/* </div> */}
                    </Modal.Body>

                    <button type="button" className={showButton ? "close" : "d-none"} onClick={handleClose1}>
                        x
                    </button>

                    {/* </div> */}
                </div>
                {showButton ? "" :
                    <p className='text-center'>Time Remaining: {seconds}s</p>
                }
            </Modal>
        </>
    )
}
